import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styles from './Nav.module.scss';
import aeicon from '../../images/axeedge-icon.svg'
import { Star, X, Menu } from 'react-feather'

const Nav = ({ siteTitle }) => {

	const [menuOpen, setMenuOpen] = useState(false);

	const isActive = ({ isCurrent }) => {
        return isCurrent ? { className: `${styles.navLinksLink} ${styles.navLinksLinkActive}`} : { className: styles.navLinksLink}
	}

	const isPartiallyActive = ({ isPartiallyCurrent }) => {
		return isPartiallyCurrent ? { className: `${styles.navLinksLink} ${styles.navLinksLinkActive}`} : { className: styles.navLinksLink}
	}
	
	useEffect(() => {
		if (!menuOpen) {
			document.body.classList.remove('preventScroll');
		}
	}, [menuOpen])
	
	const toggleMenu = () => {
		if (!menuOpen) {
			setMenuOpen(true);
			document.body.classList.add('preventScroll');
		} else {
			setMenuOpen(false);
			document.body.classList.remove('preventScroll');
		}
	}

	return(
		<nav className={styles.nav}>
			<Link to="/"><img className={styles.navBrand} src={aeicon} alt={siteTitle} /></Link>

			<div onClick={() => toggleMenu()} className={styles.navMenu}>
				<span className={`${styles.navMenuOpen} ${menuOpen && styles.navMenuOpenActive}`}><Menu size="30" /></span>
			</div>

			{
				menuOpen && (
					<div className={styles.navMenuContent}>
						<div onClick={() => toggleMenu()} className={styles.navMenuClose}><X size="32" /></div>
						<ul className={styles.navLinks}>
							<li><Link to='/' getProps={isActive}><Star /> <span>Home</span></Link></li>
							<li><Link to='/case-studies' getProps={isPartiallyActive}><Star />  <span>Case Studies</span></Link></li>
							<li><Link to='/contact' getProps={isActive}><Star /> <span>Say hi</span></Link></li>
						</ul>
					</div>
				)
			}
		</nav>
	)
}


Nav.propTypes = {
  siteTitle: PropTypes.string,
}

Nav.defaultProps = {
  siteTitle: ``,
}

export default Nav
